






















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import UIButtonLink from '@UI/components/buttons/UIButtonLink.vue'
import { CmsBanner, CmsLink, CmsImage } from '@Core/@types/skyway'
// @ts-ignore
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline' // should this be in UIButtonLink by default?

@Component({
  components: { UIButtonLink, ArrowRightSvg }, // should UIButtonLink be globally included?
})
export default class Banner extends Vue {
  @Prop({ type: Object, required: true }) data!: CmsBanner

  get link(): CmsLink | false {
    return this.data?.link || false
  }

  get image(): CmsImage | false {
    return this.data?.image || false
  }
}
